<template>
  <layout
    :activeIndex="activeIndex"
    :activeModel.sync="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <template #left-0>
      <left-menu
        :floors="floors"
        :system="system"
        @select="leftMenuSelect"
      ></left-menu>
    </template>
    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>
    <!--    <template #right-0>-->
    <!--      <div class="position-absolute" style="right: 1rem; top: 1rem">-->
    <!--        <b-card style="width: 300px">-->
    <!--          <b-card-title>-->
    <!--            <b-avatar class="mr-50" variant="light-primary">-->
    <!--              <feather-icon icon="SunIcon"/>-->
    <!--            </b-avatar>-->
    <!--            室外环境-->
    <!--          </b-card-title>-->

    <!--          <b-card-text>-->
    <!--            <b-row>-->
    <!--              <b-col cols="4">-->
    <!--								<span>-->
    <!--									{{ outDoorParams["TEMP"] || "N/A" }}-->
    <!--									<small>&nbsp;℃</small>-->
    <!--								</span>-->
    <!--                <p>TEMP</p>-->
    <!--              </b-col>-->

    <!--              <b-col cols="4">-->
    <!--                <span>{{ outDoorParams["Hum"] || "N/A" }} <small>&nbsp;%</small></span>-->
    <!--                <p>HUM</p>-->
    <!--              </b-col>-->

    <!--              <b-col cols="4">-->
    <!--                <span>{{ outDoorParams["CO2"] || "N/A" }}<small>&nbsp;ppm</small></span>-->
    <!--                <p>CO2</p>-->
    <!--              </b-col>-->

    <!--              <b-col cols="4">-->
    <!--								<span>-->
    <!--									{{ outDoorParams["PM1.0"] || "N/A" }}-->
    <!--									<small>&nbsp;μg/m<sup>3</sup></small>-->
    <!--								</span>-->
    <!--                <p>PM1.0</p>-->
    <!--              </b-col>-->

    <!--              <b-col cols="4">-->
    <!--								<span>-->
    <!--									{{ outDoorParams["PM2.5"] || "N/A" }}-->
    <!--									<small>&nbsp;μg/m<sup>3</sup></small>-->
    <!--								</span>-->
    <!--                <p>PM2.5</p>-->
    <!--              </b-col>-->

    <!--              <b-col cols="4">-->
    <!--								<span>-->
    <!--									{{ outDoorParams["PM10"] || "N/A" }}-->
    <!--									<small>&nbsp;μg/m<sup>3</sup></small>-->
    <!--								</span>-->
    <!--                <p>PM10</p>-->
    <!--              </b-col>-->
    <!--            </b-row>-->
    <!--          </b-card-text>-->
    <!--        </b-card>-->
    <!--      </div>-->
    <!--    </template>-->
    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          striped
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
          <template v-slot:cell(params)="data">
            <template v-for="(param, param_index) in data.item.params">
              <span
                v-if="param.param_name"
                :style="param.cur_value === null ? 'opacity:0.3' : ''"
              >
                <span class="mx-50" v-if="param_index !== 0">,</span>
                <span>{{
                  param.param_name.replace(data.item.equip_name + "-", "")
                }}</span
                >:
                <span v-if="param.cur_value === null">N/A</span>
                <span v-else>{{
                  param.unit && param.unit.indexOf("||") > -1
                    ? param.unit.split("||")[param.cur_value > 0 ? 1 : 0]
                    : param.cur_value + " " + (param.unit || "")
                }}</span>
              </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm"
                >查看
              </b-button>
              <b-button
                variant="success"
                size="sm"
                @click="equip_to_detail_model(data.item)"
                :disabled="!data.item.detail_model_key"
                >详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="items.length"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      ></b-pagination>
    </template>
    <template #right-2>
      <panel-group :show-title="false" group-uid="b7a334ee"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { reactive, ref, toRefs, watch } from "@vue/composition-api";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "intelligent-environment",
  components: {
    vSelect,
    Layout,
    LeftMenu,
    AppCollapse,
    AppCollapseItem,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "environment_system/environment/environment",
            dbIds: ["equipment_"],
          },
        },
        { title: "设备列表" },
        { title: "数据分析" },
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "RF",
          value: 13,
          model: {
            path: "environment_system/environment_ROOF/environment_ROOF",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F12",
          value: 12,
          model: {
            path: "environment_system/environment_F11/environment_F11",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F11",
          value: 11,
          model: {
            path: "environment_system/environment_F10/environment_F10",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F10",
          value: 10,
          model: {
            path: "environment_system/environment_F9/environment_F9",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F9",
          value: 9,
          model: {
            path: "environment_system/environment_F8/environment_F8",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F8",
          value: 8,
          model: {
            path: "environment_system/environment_F7/environment_F7",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F7",
          value: 7,
          model: {
            path: "environment_system/environment_F6/environment_F6",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "environment_system/environment_F5/environment_F5",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F5",
          value: 5,
          model: {
            path: "environment_system/environment_F4/environment_F4",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F3",
          value: 3,
          model: {
            path: "environment_system/environment_F3/environment_F3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F2",
          value: 2,
          model: {
            path: "environment_system/environment_F2/environment_F2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F1",
          value: 1,
          model: {
            path: "environment_system/environment_F1/environment_F1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B1",
          value: -1,
          model: {
            path: "environment_system/environment_B1/environment_B1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "environment_system/environment_B2/environment_B2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {
            path: "environment_system/environment_B3/environment_B3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B5",
          value: -5,
          model: {
            path: "environment_system/environment_B4/environment_B4",
            dbIds: ["equipment_"],
          },
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      layoutParams.activeModel = event.model;
    };
    //获取室外环境数据
    let outDoorParams = ref({});

    watch(
      () => store.state.jsf35.equip_list,
      (equip_list) => {
        let meter_code = "JSF_HJJC_CGQ_ROOF_N_PEIDIAN_126";
        let params = {};
        for (let i = 0; i < equip_list.length; i++) {
          let item = equip_list[i];
          if (item.equip_meter_code === meter_code) {
            item.params.map((item) => {
              params[item.param_code] = item.cur_value;
            });
            break;
          }
        }
        console.log("outDoorParams", params);
        outDoorParams.value = params;
      }
    );

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    const data = reactive({
      index: 0,
      selected_area: 0,
      options: [
        { value: 0, text: "全部" },
        { value: 1, text: "南楼" },
        { value: 2, text: "北楼" },
        { value: 3, text: "地下" },
      ],
      selected_floors: 0,
      options_floors: [
        { value: 0, text: "全部" },
        { value: 1, text: "F12" },
        { value: 2, text: "F11" },
        { value: 3, text: "F10" },
        { value: 4, text: "F9" },
        { value: 5, text: "F8" },
        { value: 6, text: "F7" },
        { value: 7, text: "F6" },
        { value: 8, text: "F5" },
        { value: 9, text: "F4" },
        { value: 10, text: "F3" },
        { value: 11, text: "F2" },
        { value: 12, text: "F1" },
      ],
      status: [
        {
          text: "主页",
          active: true,
        },
        {
          text: "设备列表",
          active: false,
        },
      ],
      // floors: [
      //   "F12",
      //   "F11",
      //   "F10",
      //   "F9",
      //   "F8",
      //   "F7",
      //   "F6",
      //   "F5",
      //   "F3",
      //   "F2",
      //   "F1",
      //   "B1",
      //   "B2",
      //   "B3",
      //   "B5",
      // ],

      fields: [
        { label: "ID", key: "equip_id" },
        { label: "设备名称", key: "equip_name" },
        { label: "设备编号", key: "equip_meter_code" },
        { label: "楼层", key: "floor" },
        { label: "参数状态", key: "params" },
        { label: "操作", key: "action" },
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,
    });
    watch(
      () => store.state.jsf35.equip_list,
      (list) => {
        data.items = list;
        data.rows = list.length;
      }
    );
    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };
    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "RF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      data.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      data.rows = data.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      outDoorParams,
      equip_to_detail_model,
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.weather {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
</style>
